import { createContext, ReactNode, useEffect, useState } from 'react';
import { isValidToken, setSession } from '../utils/jwt';
import { JWTContextType } from '../@types/auth';
import axios from '../utils/axios';

const AuthContext = createContext<JWTContextType | null>(null);

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, setState] = useState({ user: null, isAuthenticated: false });

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          setState((_state) => ({ ..._state, user: null, isAuthenticated: true }));
        } else {
          setState((_state) => ({ ..._state, user: null, isAuthenticated: false }));
        }
      } catch (err) {
        console.error(err);
        setState((_state) => ({ ..._state, user: null, isAuthenticated: false }));
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    const response = await axios.post('/api/auth/signin/admin', {
      email,
      password,
    });
    const { access_token } = response.data;
    const res = await axios.get(`https://eurowin.eway.dev:3013/api/user/me`, {
      headers: {
        'x-access-token': access_token
      },
    });
    localStorage.setItem('usercomplete', JSON.stringify(res.data))
    setSession(access_token);

    setState((_state) => ({ ..._state, user: null, isAuthenticated: true }));
  };

  const logout = async () => {
    setSession(null);
    setState((_state) => ({ ..._state, user: null, isAuthenticated: false }));
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
